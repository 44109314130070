@font-face {
  font-family: DIN Neuzeit Grotesk;
  font-weight: 300;
  font-style: normal;
  src: url(/fonts/dinneuzeitgrotesk_new.woff2) format("woff2");
}

@font-face {
  font-family: Futura;
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url(/fonts/futura_n4.woff2) format("woff2");
}

@font-face {
  font-family: Futura;
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url(/fonts/futura_n7.woff2) format("woff2");
}

@font-face {
  font-family: Futura;
  font-weight: 400;
  font-style: oblique;
  font-display: fallback;
  src: url(/fonts/futura_o4.woff2) format("woff2");
}

:root {
  --swiper-theme-color: #1c1b1b !important;
  --swiper-pagination-bullet-size: 6px !important;
  --swiper-pagination-bullet-horizontal-gap: 8px !important;
}

a {
  text-decoration: none !important;
}

.error-404-title {
  font-size: 13px;
  font-family:
    DIN Neuzeit Grotesk,
    sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #1c1b1b;
  transition: color 0.2s ease-in-out;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.error-title {
  font-family: Futura, sans-serif;
  font-size: 14px;
  color: #6a6a6a;
  margin-bottom: 3rem;
}

.back-to-home {
  padding: 14px 28px;
  border: 1px solid transparent;
  color: #fff;
  border-color: #c2a284;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.2em;
  font-family:
    DIN Neuzeit Grotesk,
    sans-serif;
  font-weight: 300;
  font-style: normal;
  background-color: #c2a284;
  margin-bottom: 13px;
  @media only screen and (max-width: 600px) {
    padding: 10px 24px;
    font-size: 7px;
  }
}

.main-image-slider {
  height: 86% !important;
}

.gallery-slider {
  height: 14% !important;
  margin-top: 4px !important;
}

.gallery-slider .swiper-slide-thumb-active .slider-ratio-box {
  border: 2px solid #6a6a6a !important;
  padding-bottom: 124%;
}

.slider-ratio-box {
  position: relative;
  padding-bottom: 130%;
}

.aspect-ratio-3-2  {
  position: relative;
  padding-bottom: 60%;
}

.ellipsis-text {
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4em;
}

.galery-1  {
  position: relative;
  padding-bottom: 74%;
  @media only screen and (max-width: 600px) {
    padding-bottom: 72%;
  }
}

.galery-2 {
  position: relative;
  padding-bottom: 150%;
}

.video-slider .swiper-button-prev {
  border-radius: 50%;
  min-width: 0;
  line-height: 1;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
  height: 3rem;
  width: 3rem;
}

.video-slider .swiper-button-prev::after {
  font-size: medium !important;
  color: white;
}

.video-slider .swiper-button-next {
  border-radius: 50%;
  min-width: 0;
  line-height: 1;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
  height: 3rem;
  width: 3rem;
}

.video-slider .swiper-button-next::after {
  font-size: medium !important;
  color: white;
}
